import React from "react";
import ScrollableFeed from "react-scrollable-feed";
import {
  isLastMessage,
  isSameSender,
  isSameSenderMargin,
  isSameUser,
} from "../config/ChatLogics";
import { useSelector } from "react-redux";
import MessageComponent from "./MessageComponent";

const ScrollableChat = ({ messages }) => {
  const user = useSelector((state) => state.userInfo.userInfo);
  return (
    <ScrollableFeed height={300} forceScroll={false} className="ScrollableFeed">
      {messages &&
        messages.map((m, i) => (
          <MessageComponent
            isLastMessage={isLastMessage}
            isSameSender={isSameSender}
            isSameSenderMargin={isSameSenderMargin}
            isSameUser={isSameUser}
            messages={messages}
            m={m}
            i={i}
            user={user}
          />
        ))}
    </ScrollableFeed>
  );
};

export default ScrollableChat;
